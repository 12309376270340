import { CSSProperties, FC, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AccountTree } from '@mui/icons-material';
import { Sentry } from 'react-activity';
import { useStoreProjectMutation } from '@/redux/features/projectApi';
import { Image, ImageBackdrop, ImageButton, ImageMarked } from '@/ImageButton';

type AddProjectProps = {
    organization: Organization;
    fullSmallButton?: boolean;
    refetchProjects?: () => void;
    asImageButton?: boolean;
    style?: CSSProperties;
    dialogOnly?: boolean;
    onClose?: () => void;
};

const AddProject: FC<AddProjectProps> = ({
    organization,
    fullSmallButton,
    refetchProjects,
    asImageButton,
    style,
    dialogOnly,
    onClose,
}) => {
    const [createProject, { data: project, error, isSuccess, isLoading, reset }] = useStoreProjectMutation();
    const [name, setName] = useState<string>('');
    const [open, setOpen] = useState<boolean>(dialogOnly ?? false);
    const [nameError, setNameError] = useState<string>('');
    const disabled = useMemo((): boolean => name.trim().length < 2, [name]);
    const navigate = useNavigate();
    const elmId = useMemo(() => Date.now(), []);

    const handleSubmit = () => {
        !disabled && createProject({ orgId: organization.id, name });
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
        e.key === 'Enter' && handleSubmit();
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setNameError('');
        onClose && onClose();
    };

    useEffect(() => {
        if (isSuccess && project) {
            handleClose();
            reset();
            refetchProjects && refetchProjects();
            navigate(`/organizations/${organization.id}/projects/${project.id}`);
        }
    }, [isSuccess, project]);

    useEffect(() => {
        if (error) {
            const response = error as BasicError;
            setNameError(response.data.message);
        }
    }, [error]);

    return (
        <>
            {!dialogOnly &&
                (asImageButton ? (
                    <ImageButton
                        onClick={() => setOpen(true)}
                        disabled={organization.is_locked}
                        focusRipple
                        style={{ ...style }}
                    >
                        <ImageBackdrop className={'MuiImageBackdrop-root'} />
                        <Image>
                            <Typography
                                component={'span'}
                                variant={'h5'}
                                color="white"
                                sx={{
                                    position: 'relative',
                                    p: 4,
                                    pt: 2,
                                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AccountTree sx={{ fontSize: 25, marginRight: 1 }} /> Add Project
                                </div>
                                <ImageMarked className={'MuiImageMarked-root'} />
                            </Typography>
                        </Image>
                    </ImageButton>
                ) : fullSmallButton ? (
                    <Button
                        disabled={organization.is_locked}
                        fullWidth={true}
                        size={'small'}
                        startIcon={<AccountTree />}
                        variant={'text'}
                        color={'success'}
                        onClick={() => setOpen(true)}
                    >
                        Add Project
                    </Button>
                ) : (
                    <Button
                        disabled={organization.is_locked}
                        size={'large'}
                        startIcon={<AccountTree />}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={() => setOpen(true)}
                    >
                        Add
                    </Button>
                ))}
            <Dialog
                PaperProps={{
                    elevation: 0,
                }}
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
            >
                <DialogTitle>Add a new project to {organization.name}:</DialogTitle>
                {isLoading || isSuccess ? (
                    <Grid padding={1} container display={'flex'} justifyContent={'center'}>
                        <Sentry style={{ fontSize: 30 }} />
                    </Grid>
                ) : (
                    <>
                        <DialogContent>
                            <TextField
                                error={!!nameError.length}
                                helperText={nameError.length ? nameError : ''}
                                autoFocus
                                margin={'dense'}
                                id={`project-name-${elmId}`}
                                label={'Project Name'}
                                type={'text'}
                                fullWidth
                                variant={'outlined'}
                                onChange={(e) => setName(e.target.value)}
                                onKeyDown={handleKeyDown}
                                value={name}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant={'contained'} color={'inherit'} onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                disabled={disabled}
                                startIcon={<AccountTree />}
                                variant={'contained'}
                                color={'secondary'}
                                onClick={handleSubmit}
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default AddProject;

type OrganizationPricingTier = {
    title: string;
    maxQuantity: number;
    priceMonthly: number;
    stripePriceMonthly: OrganizationPrice;
    details: string[];
    disabled: boolean;
};

type OrganizationPricing = {
    pricing: OrganizationPricingTier[];
};

const useOrganizationPricing = (): OrganizationPricing => {
    const pricing: OrganizationPricingTier[] = [
        {
            disabled: false,
            title: 'Solo',
            maxQuantity: 1,
            priceMonthly: 7.0,
            stripePriceMonthly: 'solo-monthly',
            details: [
                '50 GB organization storage',
                'Unlimited sharing',
                'ProRes video exporting',
                'Blender plugin access',
                'Discord & Slack webhooks',
            ],
        },
        {
            disabled: false,
            title: 'Team',
            maxQuantity: 40,
            priceMonthly: 20.0,
            stripePriceMonthly: 'team-monthly',
            details: [
                'Everything in Solo plus:',
                '250 GB organization storage',
                'Organization-specific member permissions',
                'Unlimited projects',
                'Unlimited viewers and commenters',
                'Model approvals',
                'Up to 40 paid seats (editors, reviewers, admins)',
                'Access to beta features',
            ],
        },
        {
            disabled: false,
            title: 'Enterprise',
            maxQuantity: 100,
            priceMonthly: 75.0,
            stripePriceMonthly: 'enterprise-monthly',
            details: [
                'Everything in Team plus:',
                '1 TB organization storage',
                'Unlimited contributor access',
                'Project-specific member permissions',
                'Cross-organization migration',
                'Model lifecycle management',
                'Two-factor authentication',
                'Single sign-on',
                'DRM encryption',
                'Priority support',
                'Scales to unlimited users and paid seats',
            ],
        },
    ];

    return { pricing };
};

export default useOrganizationPricing;

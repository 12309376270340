import { FC } from 'react';
import { Card, CardActions, CardHeader, Chip, Grid, Stack, Typography, useTheme } from '@mui/material';
import { HourglassTop } from '@mui/icons-material';
import useOrganizationPricing from '@/hooks/useOrganizationPricing';

const OrganizationSubscriptionPreview: FC = () => {
    const { pricing } = useOrganizationPricing();
    const theme = useTheme();

    return (
        <Grid container spacing={3} justifyContent={'center'} marginTop={0.5}>
            {pricing.map((tier) => (
                <Grid xs={12} lg={4} item key={tier.title}>
                    <Card
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        elevation={5}
                    >
                        <CardHeader
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                            }}
                            title={
                                <Typography sx={{ textAlign: 'center' }} variant={'h4'}>
                                    {tier.title.toUpperCase()}
                                </Typography>
                            }
                            subheader={
                                <ul style={{ color: theme.palette.grey[100] }}>
                                    {tier.details.map((detail, i) => (
                                        <li key={i}>{detail}</li>
                                    ))}
                                </ul>
                            }
                            titleTypographyProps={{
                                variant: 'h4',
                            }}
                            subheaderTypographyProps={{
                                component: 'span',
                            }}
                        />
                        <CardActions sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                            <Stack spacing={1} direction={'row'} sx={{ mb: 1, alignItems: 'center' }}>
                                {tier.maxQuantity === 1 ? (
                                    <Typography variant={'h5'}>
                                        ${tier.priceMonthly} / <Typography variant={'caption'}>per month</Typography>
                                    </Typography>
                                ) : (
                                    <Typography variant={'h5'}>
                                        ${tier.priceMonthly} /{' '}
                                        <Typography variant={'caption'}>per month / seat</Typography>
                                    </Typography>
                                )}
                                {tier.stripePriceMonthly !== 'enterprise-monthly' && (
                                    <Chip
                                        sx={{
                                            '& .MuiChip-icon': {
                                                fontSize: 12,
                                            },
                                        }}
                                        size={'small'}
                                        label={
                                            <Typography sx={{ fontSize: 11 }} variant={'caption'}>
                                                Limited Offer
                                            </Typography>
                                        }
                                        color={'info'}
                                        variant={'outlined'}
                                        icon={<HourglassTop />}
                                    />
                                )}
                            </Stack>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default OrganizationSubscriptionPreview;

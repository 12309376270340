import { FC, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItemAvatar,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useStoreOrganizationMutation } from '@/redux/features/organizationApi';
import { useNavigate } from 'react-router-dom';
import { Business, DomainAdd } from '@mui/icons-material';
import { Spinner } from 'react-activity';

type AddOrganizationProps = {
    asButton?: boolean;
    asMenuItem?: boolean;
    inline?: boolean;
    onSuccess?: () => void;
};

const AddOrganization: FC<AddOrganizationProps> = ({ asButton, asMenuItem, inline, onSuccess }) => {
    const [createOrg, { data: organization, error, isSuccess, isLoading, reset }] = useStoreOrganizationMutation();
    const [name, setName] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [nameError, setNameError] = useState<string>('');
    const disabled = useMemo((): boolean => name.trim().length < 2, [name]);
    const navigate = useNavigate();
    const elmId = useMemo(() => Date.now(), []);

    const handleSubmit = () => {
        !disabled && createOrg({ name });
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
        e.key === 'Enter' && handleSubmit();
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setNameError('');
    };

    useEffect(() => {
        if (isSuccess && organization) {
            setName('');
            setOpen(false);
            reset();

            if (inline) {
                onSuccess && onSuccess();
            }

            navigate(`/organizations/${organization.id}/billing`);
        }
    }, [isSuccess, organization, navigate]);

    useEffect(() => {
        if (error) {
            const response = error as BasicError;
            setNameError(response.data.message);
        }
    }, [error]);

    if (inline) {
        return (
            <Stack spacing={1} direction={'row'}>
                <TextField
                    disabled={isLoading || isSuccess}
                    error={!!nameError.length}
                    helperText={nameError.length ? nameError : ''}
                    autoFocus
                    size={'small'}
                    margin={'dense'}
                    id={`organization-name-${elmId}`}
                    label={'Organization Name'}
                    type={'text'}
                    fullWidth
                    variant={'outlined'}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    value={name}
                />
                <Button
                    sx={{ width: 200 }}
                    size={'small'}
                    disabled={disabled || isLoading || isSuccess}
                    startIcon={isLoading ? <Spinner /> : <Business />}
                    variant={'contained'}
                    color={'secondary'}
                    onClick={handleSubmit}
                >
                    Create
                </Button>
            </Stack>
        );
    }

    return (
        <>
            {asMenuItem ? (
                <Tooltip placement={'right'} title={<Typography>Create Organization</Typography>} arrow>
                    <MenuItem onClick={() => setOpen(true)}>
                        <ListItemAvatar>
                            <Avatar
                                sx={{
                                    backgroundColor: (theme) => theme.palette.text.secondary,
                                }}
                            >
                                <DomainAdd sx={{ fontSize: 28 }} />
                            </Avatar>
                        </ListItemAvatar>
                    </MenuItem>
                </Tooltip>
            ) : asButton ? (
                <Button
                    size={'large'}
                    startIcon={<DomainAdd />}
                    variant={'contained'}
                    color={'secondary'}
                    onClick={() => setOpen(true)}
                >
                    Create
                </Button>
            ) : (
                <IconButton size={'large'} onClick={() => setOpen(true)}>
                    <DomainAdd sx={{ fontSize: 26 }} />
                </IconButton>
            )}
            <Dialog
                PaperProps={{
                    elevation: 0,
                }}
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
            >
                <DialogTitle>Create a new organization:</DialogTitle>
                <DialogContent>
                    <TextField
                        disabled={isLoading || isSuccess}
                        error={!!nameError.length}
                        helperText={nameError.length ? nameError : ''}
                        autoFocus
                        margin={'dense'}
                        id={`organization-name-${elmId}`}
                        label={'Organization Name'}
                        type={'text'}
                        fullWidth
                        variant={'outlined'}
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={handleKeyDown}
                        value={name}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} color={'inherit'} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        disabled={disabled || isLoading || isSuccess}
                        startIcon={isLoading ? <Spinner /> : <Business />}
                        variant={'contained'}
                        color={'secondary'}
                        onClick={handleSubmit}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddOrganization;

import { FC, useState } from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Stack,
} from '@mui/material';
import UserSubscriptionComponent from '@/Billing/User/UserSubscription';
import UserSubscriptionChip from '@/Billing/User/UserSubscriptionChip';
import UserStorageBar from '@/Users/UserStorageBar';
import OrganizationSubscriptionPreview from '@/Billing/Organization/OrganizationSubscriptionPreview';
import AddOrganization from '@/Organizations/AddOrganization';

type UserSubscriptionModalProps = {
    subscription?: UserSubscription | null;
    onClose?: () => void;
    dialogOnly?: boolean;
    message?: string;
};

const UserSubscriptionModal: FC<UserSubscriptionModalProps> = ({ subscription, onClose, dialogOnly, message }) => {
    const [open, setOpen] = useState<boolean>(dialogOnly ?? false);
    const [processing, setProcessing] = useState<boolean>(false);

    const handleClose = () => {
        if (!processing) {
            setOpen(false);
            onClose && onClose();
        }
    };

    return (
        <>
            {!dialogOnly && (
                <Button
                    fullWidth={true}
                    variant={'contained'}
                    color={'secondary'}
                    size={'small'}
                    onClick={() => setOpen(true)}
                >
                    Upgrade
                </Button>
            )}
            <Dialog
                PaperProps={{
                    elevation: 0,
                }}
                open={open}
                fullWidth={true}
                maxWidth={'xl'}
                onClose={handleClose}
            >
                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    Subscription:
                    <Stack
                        sx={{ marginLeft: 1, width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
                        direction={'row'}
                        spacing={2}
                    >
                        <UserSubscriptionChip {...{ subscription }} />
                        <UserStorageBar {...{ compact: true }} />
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true}>
                    {message && (
                        <>
                            <Alert severity={'info'}>
                                <AlertTitle>{message}</AlertTitle>
                            </Alert>
                            <Divider sx={{ my: 2 }} />
                        </>
                    )}
                    <UserSubscriptionComponent {...{ setProcessing }} />
                    <Divider sx={{ mt: 4, mb: 2 }}>
                        <Chip label={'Upgrade to Organization'} size={'small'} />
                    </Divider>
                    <Grid container justifyContent={'center'}>
                        <Grid sx={{ p: 1.5 }} elevation={1} component={Paper} item xs={12} md={6}>
                            <AddOrganization
                                {...{
                                    inline: true,
                                    onSuccess: () => setOpen(false),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <OrganizationSubscriptionPreview />
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} color={'inherit'} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserSubscriptionModal;
